import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import api from 'axios'
import { useForm } from 'react-hook-form'

import { Grid, GridColumn, GridRow } from '../components/Grid'
import Layout from '../components/Layout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'
import ContactInfo from '../components/ContactInfo'

import '../styles/form.scss'

export const query = graphql`
{
  markdownRemark(fileAbsolutePath: {regex: "/(contact/_index)/"}) {
    frontmatter {
      seo {
        seo_description
        seo_title
      }
      welcome {
        welcome_content
        welcome_title
        welcome_image
      }
    }
  }
}
`

const Solutions = ({ data }) => {
  const ContactData = data.markdownRemark.frontmatter
  const { register, handleSubmit } = useForm()
  const [formStatus, setFormStatus] = useState('');

  const onSubmit = values => {
    const data = {}
    const formElements = Object.entries(values)
    formElements.map(([key, value]) => (data[key] = value))

    api({
      method: 'POST',
      url: 'https://zomb927yq5.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer',
      data: JSON.stringify(data)
    }).then((response)=>{
      if (response.status === 200) {
        setFormStatus('success')
      } else {
        setFormStatus('error')
      }
    }).catch((errors) => {
      setFormStatus('error')
    })
  }
  
  return(
    <Layout>
      <SEO title={ ContactData.seo.seo_title  } description={ ContactData.seo.seo_description }/>
      
      <Welcome
        title={ ContactData.welcome.welcome_title }
        image={ ContactData.welcome.welcome_image }
        content={ ContactData.welcome.welcome_content } />

      <Grid>
        <GridRow>
          <GridColumn>
            <ContactInfo />
          </GridColumn>

          <GridColumn>
            { formStatus === '' &&
              <form onSubmit={ handleSubmit(onSubmit) }>
                <input type="hidden" name="ses_address" defaultValue="infrati@egbsystems.com.br" ref={register} />

                <input type="hidden" name="send_to" defaultValue="comercial@egbsystems.com.br" ref={register} />

                <input type="hidden" name="subject" defaultValue="Mensagem enviada via site: Contato" ref={register} />

                <div className="form__row">
                  <label htmlFor="full_name">Nome completo</label>
                  <input type="text" name="nome_completo" id="full_name" ref={register({ required: true })} />
                </div>

                <div className="form__row">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" ref={register({ required: true })} />
                </div>

                <div className="form__row">
                  <label htmlFor="business">Empresa</label>
                  <input type="text" name="nome_empresa" id="business" ref={register({ required: true })} />
                </div>

                <div className="form__row">
                  <label htmlFor="phone">Telefone</label>
                  <input type="tel" name="telefone" id="phone" ref={register({ required: true })} />
                </div>

                <div className="form__row">
                  <label htmlFor="message">Mensagem</label>
                  <textarea name="mensagem" id="message" rows="5" ref={register({ required: true })}></textarea>
                </div>

                <div className="form__row-radio">
                  <input type="checkbox" name="accept" id="accept" ref={register({ required: true })} />
                  <label htmlFor="accept">Ao clicar em enviar, você confirma que leu e concorda com nossa <Link to="/politica-de-privacidade">política de privacidade</Link></label>
                </div>

                <div className="form__button">
                  <button type="submit" className="form__button">Enviar</button>
                </div>
              </form>
            }

            { formStatus === 'success' &&
                <div className="form__validation form__validation--success">
                  <p><strong>Mensagem enviada com sucesso.</strong>
                  <br />Em breve entraremos em contato.</p>
                </div>
            }
            
            { formStatus === 'error' &&
                <div className="form__validation form__validation--error">
                  <p>Mensagem não enviada. Tente novamente ou envie um email para contato@egbsystems.com.br</p>
                </div>
            }
          </GridColumn>
        </GridRow>
      </Grid>

    </Layout>
  )
}

export default Solutions

