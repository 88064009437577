import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import showdown from 'showdown'

import './contact-info.scss'

const ContactInfo = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(contact/_index)/"}) {
            frontmatter {
              content {
                title
                body
              }
            }
          }
        }
        `}
        render={ (data) => {
          const WorkInfoData = data.markdownRemark.frontmatter.content
          const converter = new showdown.Converter()

            return(
              <div className="contact-info">
                <div className="contact-info__block">
                  <h3>{ WorkInfoData.title }</h3>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(WorkInfoData.body) }}></div>
                </div>
              </div>
            )
        }}
  ></StaticQuery>
)

export default ContactInfo
